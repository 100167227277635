export default class AddToCart {
    constructor(button) {
        this.productID = button.dataset.prodid;
        this.button = button;
        this.loader = button?.offsetParent?.querySelector('.product-loader');
        this.counterContainers = document?.querySelectorAll('.cart-counter');

        this.initListeners();
    }

    handleAddToCart = async (e) => {
        e.preventDefault();
        const formData = await this.createFormData();
        await this.toggleLoaderClass();
        const response = await fetch(global_vars.ajax, {
            method: 'POST',
            body: formData,
        });

        const value = await response.json();
        await this.changeCounter(value);
        await this.toggleLoaderClass();

        this.button.classList.add('added-to-cart');
    };

    changeCounter = (value) => {
        return new Promise((resolve, reject) => {
            this.counterContainers.forEach((counter) => {
                counter.innerHTML = value;
            });
            resolve();
        });
    };

    toggleLoaderClass = async () => {
        return new Promise((resolve, reject) => {
            if (this.button.classList.contains('custom-button')) {
                this.loader = this.button.querySelector('.button-loader');
            }

            if (this.loader === undefined || this.loader === null) resolve();

            this.loader.classList.toggle('hidden');

            resolve();
        });
    };

    getLoader = () => {
        return new Promise((resolve, reject) => {});
    };

    createFormData = () => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('prodID', this.productID);
            formData.append('action', 'add_to_cart');

            resolve(formData);
        });
    };

    initListeners = async () => {
        this.button.addEventListener('click', this.handleAddToCart);
    };
}
