import { getHiddenElementHeight } from "./helpers";
import { gsap } from "gsap";

export default class Dropdown {
  constructor(dropdown) {
    this.dropdown = dropdown.parentNode.querySelector(".header-dropdown-container");
    this.dropdownList = dropdown;
    this.dropdownIsVisible = false;
    this.dropdownListHeight = getHiddenElementHeight(this.dropdownList);
    this.tl = gsap.timeline();

    this.initTimeLine();
    this.initListeners();
  }

  hanldeOpenDropdown = async () => {
    this.dropdownList.classList.toggle("dropdown-list-visible");

    if (!this.dropdownIsVisible) {
      this.tl.play();
    } else {
      this.tl.reverse();
    }

    this.dropdownIsVisible = !this.dropdownIsVisible;
  };

  initTimeLine = async () => {
    this.tl.to(this.dropdownList, { height: getHiddenElementHeight(this.dropdownList), duration: 0.4, opacity: 100 });
    this.tl.pause();
  };

  initListeners = async () => {
    this.dropdown.addEventListener("click", this.hanldeOpenDropdown);
  };
}
