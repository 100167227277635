import lazyLoadImages from './Components/lazyLoad';
import MainSlider from './Components/main-slider.js';
import Tabs from './Components/productsTabs.js';
import NavBar from './Components/navbar';
import initAddToCart from './Components/initAddToCart';
import Dropdown from './Components/dropdown';
import AddToWhishList from './Components/addToWhishList';
import Cookies from './Components/CookiesPopup';
import Popup from './Components/Popup';
import CategoryDesc from './Components/CategoryDesc';

require('fslightbox');

document.addEventListener('DOMContentLoaded', () => {
    lazyLoadImages();

    initNavbar();

    initAsyncAddToCart();

    handleInitDropdown();

    handleWhishList();

    initCategoryDescSections();

    new Cookies();

    new Popup().init();

    const mainSliders = document.querySelectorAll('.hero-slider');

    if (mainSliders.length > 0) {
        mainSliders.forEach((slider) => {
            new MainSlider(slider);
        });
    }

    const tabs = document.querySelectorAll('.section-tabs');

    if (tabs.length > 0) {
        tabs.forEach((section) => {
            new Tabs(section);
        });
    }
});

const initCategoryDescSections = () => {
    document.querySelectorAll('.category-description-section').forEach((section) => {
        new CategoryDesc(section);
    });
};

//NavBar async init

const initNavbar = async () => {
    return new Promise((resolve, reject) => {
        new NavBar();
        resolve();
    });
};

//Handle async add to Cart

const initAsyncAddToCart = async () => {
    new initAddToCart();
};

//Handle Dropdown

const handleInitDropdown = async () => {
    try {
        const dropdowns = await isUserLogged();
        await initDropdown(dropdowns);
    } catch (error) {}
};

const isUserLogged = () => {
    return new Promise((resolve, reject) => {
        const dropdowns = document.querySelectorAll('.dropdown-list');
        dropdowns.length > 0 ? resolve(dropdowns) : reject();
    });
};

const initDropdown = (dropdowns) => {
    return new Promise((resolve, reject) => {
        dropdowns.forEach((dropdown) => {
            new Dropdown(dropdown);
        });
        resolve();
    });
};

const handleWhishList = async () => {
    if (document.querySelector('.yith-wcwl-form ')) {
        jQuery(document).on('ajaxComplete', (e) => {
            initAsyncAddToCart();
        });
    }
};
