import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { disableScroll, enableScroll } from './helpers.js';

export default class NavBar {
    constructor() {
        gsap.registerPlugin(ScrollTrigger);

        this.navigation = document.querySelector('.header-navigation');
        this.hamburger = document.querySelector('.header-hamburger');
        this.navDropdowns = document.querySelectorAll('.my-menu-item.my-menu-item-dropdown');
        this.header = document.querySelector('.header');
        this.tl = gsap.timeline();
        this.isNavOpen = false;

        this.initListeners();
        this.isHeaderScrolledListener();
        this.initTimeLine();
    }

    isHeaderScrolledListener = () => {
        ScrollTrigger.create({
            start: 'top -50',
            end: 99999,
            toggleClass: { className: 'header-scrolled', targets: this.header },
        });
    };

    handleMenuOpen = () => {
        this.navigation.classList.toggle('header-navigation-open');
        this.tl.play();

        if (!this.isNavOpen) {
            this.tl.play();
            this.isNavOpen = !this.isNavOpen;
            disableScroll();
        } else {
            this.tl.reverse();
            this.isNavOpen = !this.isNavOpen;
            enableScroll();
        }
    };

    initTimeLine = () => {
        this.tl.to(this.hamburger, { height: '20px', duration: 0.2 }, 'start');
        this.tl.to('.middle-bar', { opacity: 0, duration: 0.2 }, 'start');
        this.tl.to('.top-bar', { width: '28px', duration: 0.2, y: -3 }, 'start');
        this.tl.to('.bottom-bar', { y: 3, duration: 0.2 }, 'start');
        this.tl.to('.top-bar', { rotate: 45, y: 10, duration: 0.1 }, 'end');
        this.tl.to('.bottom-bar', { rotate: -45, y: -4, duration: 0.1 }, 'end');

        this.tl.pause();
    };

    handleNavDropdown = (el) => {
        if (this.navigation && this.navigation.classList.contains('header-navigation-open')) {
            el.classList.toggle('active');
        }
    };

    initListeners = () => {
        this.hamburger.addEventListener('click', this.handleMenuOpen);
        if (this.navDropdowns) {
            this.navDropdowns.forEach((drp) => {
                drp.addEventListener('click', () => {
                    this.handleNavDropdown(drp);
                });
            });
        }
    };
}
