import { cookieGet, cookieSet, disableScroll, enableScroll } from './helpers';
import LazyLoad from './lazyLoad';
import InitAddToCart from './initAddToCart';

export default class Tabs {
    constructor(container) {
        this.buttons = container.querySelectorAll('.change-button');
        this.contentContainer = container.querySelector('.content-container');
        this.loader = container.querySelector('.loader');

        cookieGet('tab_type') === null ? (this.activeTab = 'newest') : (this.activeTab = cookieGet('tab_type'));
        this.setActiveButton();
        this.initListeners();
    }

    setActiveButton = () => {
        this.activeButton = document.querySelector(`[data-tab="${this.activeTab}"]`);
        this.toggleButtonClass(this.activeButton);
    };

    toggleButtonClass = (button) => {
        button.classList.toggle('button-ghost-active');
    };

    handleButtonClick = ({ currentTarget }) => {
        if (this.activeButton === currentTarget) return;

        this.toggleButtonClass(currentTarget);
        this.toggleButtonClass(this.activeButton);
        this.activeButton = currentTarget;

        const formData = this.createFormData();

        this.fetchData(formData);
        cookieSet('tab_type', this.activeButton.dataset.tab, '/', 1);
    };

    createFormData = () => {
        const formData = new FormData();
        formData.append('tab', this.activeButton.dataset.tab);
        formData.append('action', 'get_products');

        return formData;
    };

    fetchData = (formData) => {
        disableScroll();
        this.loader.style.display = 'flex';

        fetch(global_vars.ajax, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((resp) => (this.contentContainer.innerHTML = resp))
            .then(() => LazyLoad())
            .then(() => (this.loader.style.display = 'none'))
            .then(() => enableScroll())
            .then(() => new InitAddToCart());
    };

    initListeners = () => {
        this.buttons.forEach((button) => {
            button.addEventListener('click', (event) => {
                this.handleButtonClick(event);
            });
        });
    };
}
