import { cookieSet } from './helpers';

export default class Popup {
    constructor() {
        this.popup = document.querySelector('.info-popup');
        this.closeButtons = document.querySelectorAll('.info-popup-close');
    }

    init() {
        this.closeButtons.forEach((button) => {
            button.addEventListener('click', () => {
                this.closePopup();
            });
        });
    }

    closePopup() {
        console.log(this.popup);
        //add display none style to popup
        this.popup.style.display = 'none';

        //set popup was visible cookie for one day
        cookieSet('popupWasVisible', 'true', 1, 1);

        this.closeButtons.forEach((button) => {
            button.removeEventListener('click', () => {
                this.closePopup();
            });
        });

        setTimeout(() => {
            this.popup.remove();
        }, 500);
    }
}
