import gsap from 'gsap';

export default class CategoryDesc {
    constructor(section) {
        this.section = section;
        this.button = section.querySelector('.category-description-toggle');
        this.content = section.querySelector('.prose');
        if (this.button && this.content) {
            this.init();
        }
    }

    init = () => {
        if (!this.isTextLong()) return;

        this.button.addEventListener('click', () => {
            if (this.section.classList.contains('expanded')) {
                console.log('hiding');
                gsap.to(this.content, { height: '12.5rem', duration: 1 });
                this.section.classList.remove('expanded');
            } else {
                console.log('expanding');
                gsap.to(this.content, { height: 'auto', duration: 1 });
                this.section.classList.add('expanded');
            }
        });
    };

    isTextLong = () => {
        const text = this.content.textContent;
        // console.log(text, text.length);

        if (text.length < 800) {
            this.button.classList.add('hidden');
            this.button.style.opacity = 0;

            this.content.classList.remove('prose-hidden');
            return false;
        }

        return true;
    };
}
