import AddToCart from './AddToCart';

export default class initAddToCart {
    constructor() {
        this.initAddToCart();
    }

    initAddToCart = async () => {
        try {
            const items = await this.getAddToCartButton();
            await this.initButtons(items);
        } catch (e) {}
    };

    initButtons = (buttons) => {
        return new Promise((resolve, reject) => {
            // initAddToCart;

            buttons.forEach((button) => {
                new AddToCart(button);
            });

            resolve();
        });
    };

    getAddToCartButton = () => {
        return new Promise((resolve, reject) => {
            const buttons = document.querySelectorAll('.addToCartButton');

            if (buttons.length > 0) resolve([...buttons]);

            reject();
        });
    };
}
