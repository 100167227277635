import LazyLoad from "vanilla-lazyload";
import { unwrap } from "./helpers";

const lazyLoadImages = () =>
  new LazyLoad({
    elements_selector: "img[data-src], .lazy, [data-bg]",
    to_webp: true,
    callback_loaded: (item) => {
      const container = item.closest(".lazy-container");
      if (container) {
        container.classList.add("lazy-loaded");
        setTimeout(
          (image) => {
            image.parentElement.querySelector(".lazy-spacer").remove();
            unwrap(image.parentElement);
          },
          450,
          item
        );
      }
    },
  });

export default lazyLoadImages;
